/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import type { GraphQlCustomer } from '@/helpers/graphql'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { formatMoney } from '@/helpers/formatMoney'
import dynamic from 'next/dynamic'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { useUserContext } from '@/components/UserLoginSignUpWithContext'
import { genSize } from '@/components/dls/icons/IconRenderer'

const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const UserIcon = dynamic(import('@csc/dls/Icons/User'))
const Text = dynamic(import('@csc/dls/Text'))
const HeartIcon = dynamic(import('@/components/dls/icons/HeartIcon'))
interface UserLoginProps {
  user: GraphQlCustomer | null,
  zGoldBalance: number | null,
}

const UserLogin: React.FC<UserLoginProps> = ({
  user,
  zGoldBalance: initialZGoldBalance,
}) => {
  const { openAuthModal } = useUserContext()
  const [isInProgram, setIsInProgram] = useState(false)
  const [zGoldBalance, setZGoldBalance] = useState(initialZGoldBalance)
  const isSameAsBalance = (value: unknown) => value === zGoldBalance
  useEffect(() => {
    window.shoptoken?.subscribeToBalanceFastRefresh((value) => {
      if (!isSameAsBalance(value)) {
        setZGoldBalance(parseFloat(String(value)))
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInProgram])
  useEffect(() => {
    const timeInterval = setInterval(() => {
      setIsInProgram(
        isFeatureEnabled('isLoyaltyProgramEnabled')
        && !!window.shoptoken?.isCustomerInLoyaltyProgram(),
      )
    }, 1000)
    return () => clearInterval(timeInterval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zGoldBalance])
  const handleLogoutClick = async () => {
    const [
      { clearSavedAddressOnCheckout },
      { signUserOut },
      { KustomerLogOut },
    ] = await allPromisesWithRetries(() => [
      import('@/helpers/clearSavedAddressOnCheckout'),
      import('@/helpers/graphql'),
      import('@/components/ScriptIntegrations/KustomerChatIntegration'),
    ])
    await Promise.all([
      clearSavedAddressOnCheckout(),
      signUserOut(),
      KustomerLogOut(),
      localStorage.clear(),
    ])
    if (typeof window === 'object') {
      localStorage.setItem('loginRedirectLink', JSON.stringify(window.location.href))
    }
    window.location.href = '/login'
  }
  const hasSomeItemOnSomeWishList = user?.wishlists.edges.some((wl) => (
    wl.node.items.edges.length > 0
  ))
  return (
    <div id="sign-in-header" className="h idden group md:block relative z-200">
      <a
        id="topNav-signIn"
        href={user ? '/account' : '/login'}
      >
        <IconRenderer IconComponent={UserIcon} size="md" className="" />
      </a>
      <div
        className="hidden group-hover:block w-6 h-1 absolute top-7 left-1/2 transform -translate-x-1/2"
        style={{
          backgroundColor: '#3B5F58',
        }}
      />
      <div
        className="hidden group-hover:block w-26 h-6 absolute top-2 left-1/2 transform -translate-x-1/2"
      />
      <div className="hidden w-28 group-hover:block absolute top-8 border p-4 left-1/2 transform -translate-x-1/2 bg-white">
        {
        user
          ? <a href="/account" id="topNav-signOut" className="text-left w-full"><Text size="sm">My Account</Text></a>
          : <button data-testid="sign-in-button" onClick={() => openAuthModal()} id="topNav-signIn" className="text-left w-full"><Text size="sm">Sign In</Text></button>
        }
        {
          user
            ? (
              <a
                href="/wishlists"
                className="w-full text-left flex flex-row items-center align-middle justify-start mt-4"
              >
                <HeartIcon filled={hasSomeItemOnSomeWishList} className={`mr-1 ${genSize('sm')}`} />
                <Text size="sm" className="inline-block">Wish Lists</Text>
              </a>
            )
            : (
              <button onClick={() => openAuthModal()} className="w-full text-left flex flex-row items-center align-middle justify-start mt-4">
                <HeartIcon filled={hasSomeItemOnSomeWishList} className={`mr-1 ${genSize('sm')}`} />
                <Text size="sm" className="inline-block">Wish Lists</Text>
              </button>
            )
        }
        <a href="/order-status" className="text-left w-full"><Text size="sm" className="mt-4">Track Orders</Text></a>
        { isInProgram && (
        <button
          className="mt-4 flex flex-row justify-start items-center align-middle"
          aria-label="OKL Rewards"
          data-testid="zgold-button"
          onClick={() => window.shoptoken?.showWidget()}
        >
          <div>
            <img src="/__statics/images/loyalty.svg" alt="OKL Rewards" />
          </div>
          <Text size="sm" className="ml-2" data-testid="zgold-balance">
            {formatMoney(zGoldBalance || 0)}
          </Text>
        </button>
        ) }
        {
          user
            ? <button data-testid="sign-out-button" onClick={handleLogoutClick} type="button" className="w-full text-left"><Text size="sm" className="mt-4">Log Out</Text></button>
            : null
        }
      </div>
    </div>
  )
}

export default UserLogin
